<div class="container">
  <h1>{{'TITLE' | translate}}</h1>
  <p class="subtitle">{{'SECTIONS.HOME.INFO' | translate}}</p>

  <div class="home">
    <div class="list-container recipes">
      <div class="list-header">{{'HOME_PAGE.RECIPES' | translate}}</div>
      <div class="recipes-list">
        <app-video-ad ad="video-home"></app-video-ad>
        <div class="recipe" *ngFor="let recipe of recipes" [routerLink]="recipe.internalLink">
          <div class="logo"><fa-icon [icon]="recipe.icon"></fa-icon></div>
          <div class="name">{{recipe.title | translate}}</div>
          <div class="desc">{{recipe.desc | translate}}</div>
        </div>
      </div>
    </div>

    <app-ad size="banner" bannerId="banner-ad1" id="ad1" ></app-ad>

    <div class="list-container credits">
      <div class="list-header">{{'HOME_PAGE.CREDITS' | translate}}</div>
      <div class="story">
        <p>{{'HOME_PAGE.STORY_1' | translate}}</p>
        <p>{{'HOME_PAGE.STORY_2' | translate}}</p>
        <p>{{'HOME_PAGE.STORY_3' | translate}}</p>
        <p>{{'HOME_PAGE.STORY_4' | translate}}</p>
        <p>{{'HOME_PAGE.STORY_5' | translate}}</p>
        <em>- HerrExion</em>
      </div>
      <div class="twitter">
        <a href="https://twitter.com/D2Recipes" target="_blank" class="social-button twitter"><fa-icon [icon]="['fab', 'twitter']"></fa-icon>&#64;D2Recipes</a>
        <a href="https://twitter.com/HerrExion" target="_blank" class="social-button twitter"><fa-icon [icon]="['fab', 'twitter']"></fa-icon>&#64;HerrExion</a>
      </div>
      <h3>{{'HOME_PAGE.TRANSLATORS' | translate}}</h3>
      <p [innerHTML]="'HOME_PAGE.TRANSLATORS_COMMUNITY' | translate"></p>
      <div class="translators">
        <div class="lang" *ngFor="let lang of translators">
          <img [alt]="lang.lang" [src]="'assets/flags/' + lang.lang + '.svg'">
          <div class="progression-bar">
            <div class="progress" [style.width]="'calc(' + lang.percentage + '% - 6px)'">&nbsp;{{lang.percentage}}%</div>
          </div>
          <div class="names">
            <span *ngFor="let name of lang.names">{{name}}</span>
          </div>
        </div>
      </div>
      <p [innerHTML]="'HOME_PAGE.HELP_TRANSLATE' | translate"></p>
    </div>
    <app-ad size="banner" bannerId="banner-ad2" id="ad2" [renderVisibleOnly]="true"></app-ad>
  </div>

</div>
